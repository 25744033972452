import {useQuery} from '@tanstack/react-query';

import {PACKAGES_CACHE} from 'constants/cache';
import axios from 'axios';
//@ts-ignore
import {API_URL} from 'config';
import {IPackages} from './models';

export const useGetPackages = () => {
    return useQuery<IPackages>({
        queryKey: [PACKAGES_CACHE],
        queryFn: () =>
            axios
                .get(`${API_URL}/web_api/available_packages`) // use env
                .then(response => {
                    return response.data['hydra:member']?.[0] ?? response.data;
                }),
    });
};
