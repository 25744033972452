import React, {useCallback, useMemo} from 'react';

import {isStringNumeric} from 'helpers/common';
import classNames from 'classnames';
import AccordionItem, {Accordion} from 'components/ui/Accordion/Accordion';
import InfoPopover from 'components/ui/InfoPopover/InfoPopover';
import Button from 'components/ui/Button';
import {useLanguage} from 'context/language-context';
import {
    IMetadata,
    IPackageDetails,
    IPackageValues,
    IPackages,
    IRow,
} from '../models';
import styles from './PricingComparison.module.scss';

export interface IPricingComparisonProps {
    handleClick: (packageName: string) => void;
    availablePackages?: IPackages;
    activePackage?: number;
    getPackageButtonText: (packageOrder: number) => string;
    currency: string;
    activePaymentOption: string;
}

const renderPrices = (
    packageData: IPackageDetails,
    currency: string,
    activePaymentOption: string,
) => {
    if (packageData.prices instanceof Array && !packageData.prices.length) {
        return '0';
    }

    return (packageData.prices as any)[currency.toLowerCase()][
        activePaymentOption
    ].price;
};

const replaceCurrencyPlaceholder = (text: string, currency: string) => {
    return text?.replace(/#{currency}/g, currency === 'CHF' ? 'CHF' : 'EUR');
};

const getContent = (data: string, currency: string) => {
    if (data === 'Y') {
        return <div className={styles.checkMark} data-notranslate />;
    }
    if (data === 'N') {
        return <div data-notranslate>X</div>;
    }

    return isStringNumeric(data) ? (
        <span data-notranslate>{data}</span>
    ) : (
        <span>{replaceCurrencyPlaceholder(data, currency)}</span>
    );
};

const getGridColumForCell = (
    row: IPackageValues[],
    allColumns: string[],
): string | null => {
    if (row.length === allColumns.length) return null;

    if (row.length === 1) {
        return `2/${allColumns.length + 2}`;
    }

    return null;
};

const hasSameValues = (compare: IPackageValues[], to: IPackageValues[]) => {
    if (compare?.length !== to?.length) return false;

    return compare.every((s, index) => s.value === to[index].value);
};

const sortRowValuesInOrder = (row: IRow): IRow => {
    return {
        ...row,
        packages: [...row.packages].sort((v1, v2) =>
            v1.order > v2.order ? 1 : -1,
        ),
    };
};

export const PricingComparison = ({
    handleClick,
    availablePackages,
    activePackage,
    getPackageButtonText,
    currency,
    activePaymentOption,
}: IPricingComparisonProps) => {
    const allColumns = availablePackages
        ? Object.keys(availablePackages.packages)
        : [];
    const {selectedLanguageParams} = useLanguage();

    const mergeMetadataRowsIfHasSameTextValue = useCallback(
        (metadata: IMetadata): IMetadata => {
            return {
                ...metadata,
                rows: metadata.rows.reduce<IRow[]>((acc, _curr, index) => {
                    const curr = sortRowValuesInOrder(_curr);
                    if (
                        // has any "texty" value
                        curr.packages.some(
                            p => p.value !== 'Y' && p.value !== 'N',
                        ) &&
                        // has the same values as the previous one
                        hasSameValues(
                            curr.packages,
                            metadata.rows[index - 1]?.packages,
                        )
                    ) {
                        // merge them
                        acc[acc.length - 1] = {
                            ...acc[acc.length - 1],
                            row_title: `${acc[acc.length - 1].row_title}\n${
                                curr.row_title
                            }`,
                            tooltip:
                                acc[acc.length - 1].tooltip || curr.tooltip,
                        };
                    } else {
                        acc.push(curr);
                    }
                    return acc;
                }, []),
            };
        },
        [],
    );

    const injectLanguage = useCallback(
        (url: string) => {
            const domain = url.match(/^https?:\/\/[^/]+/);

            if (selectedLanguageParams.code === 'en') {
                return url;
            }

            if (!domain) {
                return url;
            }

            const languageInjectedUrl = `${domain[0]}/${
                selectedLanguageParams.code
            }${url.substring(domain[0].length)}`;

            return languageInjectedUrl;
        },
        [selectedLanguageParams],
    );

    const renderData = useMemo(() => {
        return availablePackages?.metadata.map((metadata, index) => {
            const mergedMetadata =
                mergeMetadataRowsIfHasSameTextValue(metadata);
            return (
                <AccordionItem
                    toggleType="title"
                    key={metadata.section_title}
                    accordionKey={metadata.section_title}
                    openStatus={index === 0}
                    titleClass={styles.accordionTitle}
                    title={
                        <div className={styles.sectionTitle}>
                            <span className={styles.title}>
                                {mergedMetadata.section_title}
                                &nbsp;
                                {mergedMetadata.tooltip ? (
                                    <InfoPopover
                                        content={replaceCurrencyPlaceholder(
                                            mergedMetadata.tooltip,
                                            currency,
                                        )}
                                    />
                                ) : null}
                            </span>
                            {mergedMetadata.links?.map(link => (
                                <a
                                    href={injectLanguage(link.url)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {link.text}
                                </a>
                            ))}
                        </div>
                    }
                    body={
                        <div className={styles.gridContainer}>
                            {mergedMetadata.rows.map((r, idx) => {
                                const isSubtitle =
                                    r.level === 0 &&
                                    r.packages.every(p => p.value === null);
                                const next = mergedMetadata.rows[idx + 1];
                                const isNextSubtitle =
                                    mergedMetadata.rows[idx + 1]?.level === 0 &&
                                    mergedMetadata.rows[
                                        idx + 1
                                    ]?.packages?.every(p => p.value === null);

                                return (
                                    <React.Fragment key={r.row_title}>
                                        <div
                                            className={classNames(
                                                styles.label,
                                                {[styles.subtitle]: isSubtitle},
                                            )}
                                            style={{
                                                paddingLeft: `${
                                                    r.level + 1
                                                }rem`,
                                                gridColumn: isSubtitle
                                                    ? `1/${
                                                          allColumns.length + 2
                                                      }`
                                                    : 'unset',
                                            }}
                                        >
                                            <div>
                                                {r.row_title
                                                    .split('\n')
                                                    .map(rt => (
                                                        <p key={rt}>{rt}</p>
                                                    ))}
                                            </div>
                                            &nbsp;
                                            {r.tooltip ? (
                                                <InfoPopover
                                                    content={replaceCurrencyPlaceholder(
                                                        r.tooltip,
                                                        currency,
                                                    )}
                                                />
                                            ) : null}
                                            {r.links?.map(link => (
                                                <a
                                                    href={injectLanguage(
                                                        link.url,
                                                    )}
                                                    title={link.text}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M5.308 20.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.282V5.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h6.307V5H5.308a.294.294 0 0 0-.212.097.294.294 0 0 0-.096.211v13.385c0 .077.032.147.096.211a.294.294 0 0 0 .212.096h13.384a.294.294 0 0 0 .212-.096.294.294 0 0 0 .096-.211v-6.308h1.5v6.308c0 .505-.175.932-.525 1.282-.35.35-.778.525-1.283.525H5.308Zm4.411-5.165-1.054-1.054 9.281-9.28H14V3.5h6.5V10H19V6.054l-9.28 9.281Z"
                                                        />
                                                    </svg>
                                                </a>
                                            ))}
                                        </div>
                                        {r.packages.map((cell, cellIndex) => {
                                            if (!cell.value) {
                                                return null;
                                            }

                                            return (
                                                <div
                                                    key={`${mergedMetadata.section_title}.${r.row_title}.${cell.order}.${cell.value}`}
                                                    className={classNames(
                                                        styles.value,
                                                        {
                                                            [styles.subtitle]:
                                                                isSubtitle,
                                                        },
                                                    )}
                                                    style={{
                                                        gridColumn:
                                                            getGridColumForCell(
                                                                r.packages,
                                                                allColumns,
                                                            ) ?? undefined,
                                                    }}
                                                >
                                                    {getContent(
                                                        cell.value?.toString() ||
                                                            '',
                                                        currency,
                                                    )}
                                                    {cell.tooltip ? (
                                                        <InfoPopover
                                                            content={r.tooltip}
                                                        />
                                                    ) : null}
                                                </div>
                                            );
                                        })}
                                        {!isSubtitle &&
                                        !!next &&
                                        !isNextSubtitle ? (
                                            <div
                                                className={styles.divider}
                                                style={{
                                                    gridColumn: `1/${
                                                        allColumns.length + 2
                                                    }`,
                                                }}
                                            />
                                        ) : null}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    }
                />
            );
        });
    }, [
        allColumns,
        availablePackages,
        mergeMetadataRowsIfHasSameTextValue,
        currency,
        injectLanguage,
    ]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.gridContainer}>
                        <div className={styles.label}>Compare plans</div>
                        {availablePackages
                            ? Object.values(availablePackages.packages).map(
                                  (packageDetails, idx) => (
                                      <div
                                          key={`tablesection-${packageDetails['@id']}`}
                                          className={styles.values}
                                      >
                                          <div>
                                              {packageDetails.productName}
                                          </div>
                                          <div>
                                              <var data-var="currency">
                                                  {currency}{' '}
                                                  {renderPrices(
                                                      packageDetails,
                                                      currency,
                                                      activePaymentOption,
                                                  )}
                                              </var>
                                              /{activePaymentOption}
                                          </div>
                                          <Button
                                              onClick={() =>
                                                  handleClick(
                                                      packageDetails.productName,
                                                  )
                                              }
                                              disabled={
                                                  !!(
                                                      activePackage &&
                                                      activePackage + 1 ===
                                                          packageDetails.order &&
                                                      activePackage === 0
                                                  )
                                              }
                                              variant={
                                                  activePackage &&
                                                  activePackage + 1 ===
                                                      packageDetails.order &&
                                                  activePackage !== 0
                                                      ? 'outline'
                                                      : 'primary'
                                              }
                                              className={styles.button}
                                          >
                                              {getPackageButtonText(
                                                  packageDetails.order,
                                              )}
                                          </Button>
                                      </div>
                                  ),
                              )
                            : null}
                    </div>
                </div>
                <Accordion toggleMode="multi">{renderData}</Accordion>
                <div className={styles.footer}>
                    <div className={styles.gridContainer}>
                        <div className={styles.label} />
                        {availablePackages
                            ? Object.values(availablePackages.packages).map(
                                  (packageDetails, idx) => (
                                      <div
                                          key={`tablesection-${packageDetails['@id']}`}
                                          className={styles.values}
                                      >
                                          <div>
                                              {packageDetails.productName}
                                          </div>
                                          <div>
                                              <var data-var="currency">
                                                  {currency}{' '}
                                                  {renderPrices(
                                                      packageDetails,
                                                      currency,
                                                      activePaymentOption,
                                                  )}
                                              </var>
                                              /{activePaymentOption}
                                          </div>
                                          <Button
                                              onClick={() =>
                                                  handleClick(
                                                      packageDetails.productName,
                                                  )
                                              }
                                              disabled={
                                                  !!(
                                                      activePackage &&
                                                      activePackage + 1 ===
                                                          packageDetails.order &&
                                                      activePackage === 0
                                                  )
                                              }
                                              variant={
                                                  activePackage &&
                                                  activePackage + 1 ===
                                                      packageDetails.order &&
                                                  activePackage !== 0
                                                      ? 'outline'
                                                      : 'primary'
                                              }
                                              className={styles.button}
                                          >
                                              {getPackageButtonText(
                                                  packageDetails.order,
                                              )}
                                          </Button>
                                      </div>
                                  ),
                              )
                            : null}
                    </div>
                </div>
            </div>
        </div>
    );
};
