import classNames from 'classnames';
import {Card} from 'react-bootstrap';

import Button from 'components/ui/Button';
import {formatDateInZurichTZ} from 'helpers/dates';
import {IPackageDetails} from '../models';
import styles from '../PricingPage.module.scss';
import {BusinessPartner} from '../../../../types/api';
import {translate} from 'helpers/localize';

export interface IPackageCardProps {
    packageData: IPackageDetails;
    activePaymentOption: string;
    currency: string;
    highlight: boolean;
    handleClick: (packageName: string) => void;
    buttonText: string;
    activeBusinessPartner: BusinessPartner | null;
}

export const PackageCard = ({
    packageData,
    activePaymentOption,
    currency,
    highlight,
    handleClick,
    buttonText,
    activeBusinessPartner,
}: IPackageCardProps) => {
    const isActivePackage =
        !!activeBusinessPartner &&
        activeBusinessPartner?.activePackage + 1 === packageData.order;
    const noPaymentFees =
        activeBusinessPartner && activeBusinessPartner.pricingModel !== 1;
    const discountName = activeBusinessPartner?.discountName;
    const packageChangedAt = activeBusinessPartner?.packageChangedAt;

    const renderCardData = () => {
        return (
            <ul className={styles.metaDataList}>
                {packageData.cardContent.map(m => {
                    return (
                        <div key={packageData['@id'] + m.row_title}>
                            <p className={styles.rowTitle}>{m.row_title}</p>
                            {m.rows.map(r => (
                                <li key={packageData['@id'] + r}>
                                    <span className={styles.checkMark} />
                                    <span>{r}</span>
                                </li>
                            ))}
                        </div>
                    );
                })}
            </ul>
        );
    };

    const renderPrices = () => {
        if (packageData.prices instanceof Array && !packageData.prices.length) {
            return '0';
        }

        return (packageData.prices as any)[currency.toLowerCase()][
            activePaymentOption
        ].price;
    };

    return (
        <Card body className={classNames(styles.packageCard, {highlight})}>
            {highlight ? (
                <div className="highlight-text">Most popular</div>
            ) : null}
            <div className="card-item">
                <h3 className={styles.packageCardTitle}>
                    {packageData.productName}
                </h3>
                <div className={styles.packageCardPricing}>
                    <span className="currency" data-notranslate>
                        {currency}
                    </span>
                    <span className="price" data-notranslate>
                        {renderPrices()}
                    </span>
                    <span className="period">
                        {translate(`/${activePaymentOption}`)}
                    </span>
                </div>
            </div>
            <div className="card-item">
                <Button
                    className={styles.upgradeButton}
                    onClick={() => handleClick(packageData.productName)}
                    disabled={
                        isActivePackage &&
                        activeBusinessPartner?.activePackage === 0
                    }
                    variant={
                        isActivePackage &&
                        activeBusinessPartner?.activePackage !== 0
                            ? 'outline'
                            : 'primary'
                    }
                >
                    {buttonText}
                </Button>
                {renderCardData()}
            </div>
            <div
                className={classNames(styles.packageCardFooter, {
                    [styles.multiline]:
                        [
                            !!packageChangedAt,
                            !!discountName,
                            !!noPaymentFees,
                        ].filter(Boolean).length >= 2,
                })}
            >
                {isActivePackage && packageChangedAt ? (
                    <div>
                        Last update:{' '}
                        <span data-notranslate>
                            {formatDateInZurichTZ(packageChangedAt)}
                        </span>
                    </div>
                ) : null}
                {isActivePackage && discountName ? (
                    <div>Special agreement: {discountName}</div>
                ) : null}
                {isActivePackage && noPaymentFees ? (
                    <div>Special agreement: No payment fees</div>
                ) : null}
            </div>
        </Card>
    );
};
